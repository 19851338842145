import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Layout from "../components/page-objects/Layout";
import Header from "../components/page-objects/Header";
import BlogRow from "../components/page-objects/BlogRow";
import { graphql } from "gatsby";
import Spacer from "../components/ui-objects/Spacer";
import MediationComponent from "../components/ui-objects/MediationComponent";
import Affiliate from "../components/page-objects/Affiliate";
import UspBar from "../components/page-objects/usp-bar";
import Button from "../components/ui-objects/Button";
import Reviews from "../components/page-objects/Reviews";

// check of part of page is visible
function isVisible(value) {
  if (value) {
    return (
      'block'
    )
  } else {
    return (
      'none'
    )
  }
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout 
      // helmet seo content
      description={frontmatter.seoinfo.description}
      title={frontmatter.seoinfo.seotitle}
      keywords={frontmatter.seoinfo.keywords}
    >
      <div />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      >
        <section style={{ order: "0" }}>
          <Header
            headerImage={frontmatter.headerimagewrap.image.childImageSharp.fixed.srcWebp}
            headerImageAlttext={frontmatter.headerimagewrap.alttextheaderimage}
            promoblockVisible={frontmatter.headerpromo.headerpromovisible}
            promoblockContent={frontmatter.headerpromo.headerpromocontent}
            promoblockButtonVisible={frontmatter.headerpromo.headerpromobuttonvisible}
            promoblockButtonText={frontmatter.headerpromo.headerpromobuttonlabel}
            promoblockButtonUrl={frontmatter.headerpromo.headerpromobuttonurl}
            fullBackgroundImage={frontmatter.headerimagewrap.fullbgimage}
            headerHeight={""}
          />
          <Helmet>
            <title>{frontmatter.title}</title>
          </Helmet>
        </section>
        <section className="backgroundblock dark-gray" style={{ padding: '20px 0' }}>
          <UspBar />
        </section>
        <section
          className={"backgroundblock " + frontmatter.introwrap.introbgcolor}
          style={{ display: isVisible(frontmatter.introwrap.introvisible) }}
        >
          <div className="container" style={{ textAlign: 'center' }}>
            <h2>{frontmatter.introwrap.introheader}</h2>
            <Spacer size="l"/>
            <p dangerouslySetInnerHTML={{ __html: frontmatter.introwrap.introcontent }} />
          </div>
        </section>
        <section
          style={{
            order: frontmatter.pagevalues.positiemediationitems.position,
            display: isVisible(frontmatter.pagevalues.positiemediationitems.visible),
          }}
        >
          <section
            className={"backgroundblock " + frontmatter.mediationtypes.mediationtypesbgcolor}
            style={{ display: isVisible(frontmatter.mediationtypes.mediationtypesvisible) }}
          >
            <h3 style={{ textAlign: "center" }}>{frontmatter.headingmedtypes}</h3>
            <Spacer size={"hide-mobile"} custom="50px" />
            <div className="mediationcomponents">
              <MediationComponent
                mediationTheme={"light"}
                mediationName={frontmatter.mediationtypes.mediationtype1.mediationname1}
                mediationTitle={frontmatter.mediationtypes.mediationtype1.mediationtitle1}
                medCompName={"scheiden"}
                url={frontmatter.mediationtypes.mediationtype1.mediationurl1}
                mediationvisibility={frontmatter.mediationtypes.mediationtype1.mediation1zichtbaar}
              />
              <MediationComponent
                mediationTheme={"dark"}
                mediationName={frontmatter.mediationtypes.mediationtype2.mediationname2}
                mediationTitle={frontmatter.mediationtypes.mediationtype2.mediationtitle2}
                medCompName={"ouderschapsplan"}
                url={frontmatter.mediationtypes.mediationtype2.mediationurl2}
                mediationvisibility={frontmatter.mediationtypes.mediationtype2.mediation2zichtbaar}
              />
              <MediationComponent
                mediationTheme={"light"}
                mediationName={frontmatter.mediationtypes.mediationtype3.mediationname3}
                mediationTitle={frontmatter.mediationtypes.mediationtype3.mediationtitle3}
                medCompName={"familie"}
                url={frontmatter.mediationtypes.mediationtype3.mediationurl3}
                mediationvisibility={frontmatter.mediationtypes.mediationtype3.mediation3zichtbaar}
              />
              <MediationComponent
                mediationTheme={"light"}
                mediationName={frontmatter.mediationtypes.mediationtype4.mediationname4}
                mediationTitle={frontmatter.mediationtypes.mediationtype4.mediationtitle4}
                medCompName={"arbeidsconflict"}
                url={frontmatter.mediationtypes.mediationtype4.mediationurl4}
                mediationvisibility={frontmatter.mediationtypes.mediationtype4.mediation4zichtbaar}
              />
              <MediationComponent
                mediationTheme={"dark"}
                mediationName={frontmatter.mediationtypes.mediationtype5.mediationname5}
                mediationTitle={frontmatter.mediationtypes.mediationtype5.mediationtitle5}
                medCompName={"zakelijk"}
                url={frontmatter.mediationtypes.mediationtype5.mediationurl5}
                mediationvisibility={frontmatter.mediationtypes.mediationtype5.mediation5zichtbaar}
              />
              <MediationComponent
                mediationTheme={"light"}
                mediationName={frontmatter.mediationtypes.mediationtype6.mediationname6}
                mediationTitle={frontmatter.mediationtypes.mediationtype6.mediationtitle6}
                medCompName={"overheid"}
                url={frontmatter.mediationtypes.mediationtype6.mediationurl6}
                mediationvisibility={frontmatter.mediationtypes.mediationtype6.mediation6zichtbaar}
              />
            </div>
          </section>
          <section
            className={"backgroundblock " + frontmatter.voorstellenwrap.voorstellenbgcolor}
            style={{ display: isVisible(frontmatter.voorstellenwrap.voorstellenvisible) }}
          >
            <div className="container">
              <div className="row">
                <h3>{frontmatter.voorstellenwrap.headingvoorstellen}</h3>
                <div className="voorstellen">
                  <div className="">
                    <Spacer size="m" />
                    <p>{frontmatter.voorstellenwrap.contentvoorstellen}</p>
                    <Spacer size="l" />
                    <div>
                      <Button style={{ display: isVisible(frontmatter.voorstellenwrap.voorstellenbuttonvisible) }} url={frontmatter.voorstellenwrap.voorstellenbuttonlink} buttonType="primary" buttonLabel={frontmatter.voorstellenwrap.voorstellenbuttontext} />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <img width="230" alt={frontmatter.voorstellenwrap.voorstellenimagealttext} className="profilepicture" src={frontmatter.voorstellenwrap.voorstellenimage.childImageSharp.fixed.srcWebp} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="backgroundblock navy"
          >
            <div className="container">
              <Reviews />
            </div>
          </section>
          <section
            className={"backgroundblock " + frontmatter.werkwijzewrap.werkwijzebgcolor}
            style={{ display: isVisible(frontmatter.werkwijzewrap.werkwijzevisible) }}
          >
            <div className="container" style={{ textAlign: 'center' }}>
              <h3>{frontmatter.werkwijzewrap.headingwerkwijze}</h3>
              <Spacer size="l" />
              <p dangerouslySetInnerHTML={{ __html: frontmatter.werkwijzewrap.contentwerkwijze }} />
              <Spacer size="xl"/>
              <Button style={{ display: isVisible(frontmatter.werkwijzewrap.werkwijzebuttonvisible) }} url={frontmatter.werkwijzewrap.werkwijzebuttonurl} buttonType="primary" buttonLabel={frontmatter.werkwijzewrap.werkwijzebuttonlabel} />
            </div>
          </section>
          <section
            className="backgroundblock white"
          >
            <div className="container">
              <h3 style={{ textAlign: "center" }}>
                {frontmatter.headingblogarticles}
              </h3>
              <Spacer size="xxl" />
              <BlogRow />
            </div>
          </section>
          <section
            className="backgroundblock navy-light"
          >
            <Affiliate />
          </section>
        </section>
      </div>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
        query IndexPageTemplate {
          markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
            id
            frontmatter {
              title
              headerpromo {
                headerpromobuttonlabel
                headerpromobuttonurl
                headerpromocontent
                headerpromobuttonvisible
                headerpromovisible
              }
              headerimagewrap {
                image {
                  publicURL
                  childImageSharp {
                    fixed(webpQuality: 90, width: 1600) {
                      srcWebp
                    }
                  }
                }
                headerimagealttext
                fullbgimage
              }
              headingmedtypes
              headingblogarticles
              voorstellenwrap {
                contentvoorstellen
                headingvoorstellen
                voorstellenbuttontext
                voorstellenbuttonlink
                voorstellenbuttonvisible
                voorstellenbgcolor
                voorstellenvisible
                voorstellenimagealttext
                voorstellenimage {
                  publicURL
                  childImageSharp {
                    fixed(width: 250) {
                      srcWebp
                    }
                  }
                }
              }
              introwrap {
                introbgcolor
                introcontent
                introheader
                introvisible
              }
              heading
              subheading
              pagevalues {
                positieblogblokken {
                  visible
                  position
                }
                positievoorstellen {
                  visible
                  position
                }
                positiemediationitems {
                  visible
                  position
                }
              }
              mediationtypes {
                mediationtype1 {
                  mediationname1
                  mediationtitle1
                  mediationurl1
                  mediation1zichtbaar
                }
                mediationtype2 {
                  mediationname2
                  mediationtitle2
                  mediationurl2
                  mediation2zichtbaar
                }
                mediationtype3 {
                  mediationname3
                  mediationtitle3
                  mediationurl3
                  mediation3zichtbaar
                }
                mediationtype4 {
                  mediationname4
                  mediationtitle4
                  mediationurl4
                  mediation4zichtbaar
                }
                mediationtype5 {
                  mediationname5
                  mediationtitle5
                  mediationurl5
                  mediation5zichtbaar
                }
                mediationtype6 {
                  mediationname6
                  mediationtitle6
                  mediationurl6
                  mediation6zichtbaar
                }
                mediationtypesbgcolor
                mediationtypesvisible
              }
              werkwijzewrap {
                contentwerkwijze
                headingwerkwijze
                werkwijzebgcolor
                werkwijzebuttonlabel
                werkwijzebuttonurl
                werkwijzebuttonvisible
                werkwijzevisible
              }
              seoinfo {
								description
                seotitle
                keywords
              }
            }
          }
        }
      `;
