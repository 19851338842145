import React from "react";

const MediationComponent = props => {
  function handleClick(e) {
    e.preventDefault();
    window.location.href = props.url;
  }
  // let MedIsVisible = props.mediationvisibility;
  // let isVisible = props.mediationvisibility ? (isVisible = "block") : (isVisible = "none");
  // console.log(isVisible)

  return (
    <div
      role={"button"}
      onClick={handleClick}
      onKeyDown={handleClick}
      tabIndex={0}
      className={"mediationcomponent"}
      style={{
        backgroundImage: "url(/img/medcomps/medcomp-" + props.medCompName + ".svg)",
        display: props.mediationvisibility ? "block" : "none"
      }}
    >
      <div className={"mediationtext " + props.mediationTheme}>
        <p>{props.mediationTitle}</p>
        <h3>{props.mediationName}</h3>
      </div>
    </div>
  );
};

export default MediationComponent;
