import React from "react";
import Button from "./Button";
import Spacer from './Spacer'
import { ImQuotesRight } from '@react-icons/all-files/im/ImQuotesRight';

function CardButton(cardProps) {
  const showCardButton = cardProps.showCardButton;
  if (showCardButton) {
    return (
      <div>
        <Spacer size="l" />
        <Button
          buttonType={cardProps.buttonType}
          url={cardProps.url}
          altText={cardProps.altText}
          buttonLabel={cardProps.buttonLabel}
        />
      </div>
    );
  } else {
    return ''
  }
}

const Card = props => {

  const featuredPost = props.featuredPost;
  // console.log(props.featuredPost);
  let flexOrder = '';

  if (featuredPost) {
    flexOrder = -1;
  } else {
    flexOrder = 0;
  }
  return (
    <div
      className={props.shade ? "kic-card" : "kic-card no-shade"}
      style={{
        flex: "0 0 calc(" + props.width + " - 24px)",
        order: flexOrder,
      }}
    >
      {props.showQuote ? <div className='kic-card-quote'><div className='kic-card-quote_icon'><ImQuotesRight color="white" size="2.5em" /></div></div> : null }
      <div className={"kic-card-header"}>
        <h3>{props.cardHeader}</h3>
      </div>
      <p className={"kic-card-body"}>{props.cardContent}</p>
      <CardButton
        isFeaturedPost={props.featuredPost}
        showCardButton={props.showButton}
        url={props.url}
        buttonType={props.buttonType}
        buttonLabel={props.buttonLabel}
      />
    </div>
  );
};

export default Card;
