import React from 'react'
import MfnLogo from '../../img/mfn-registermediator-logo.png'
import VanLogo from '../../img/van-logo.png'

const Affiliate = class extends React.Component {
  render() {
    return (
      <div className='container'>
        <h3 style={{ width: '100%', textAlign: 'center' }}>Aangesloten bij deze mooie organisaties</h3>
      <div className='affiliate-block'>
        <div className='affiliate'>
            <img height="150" width="auto" loading="lazy" alt='MFN registermediator' src={MfnLogo} style={{ height: '150px', filter: 'grayscale(100%)' }} />
        </div>
        <div className='affiliate'>
            <img height="120" width="auto" loading="lazy" alt='Vereniging Arbeidsmediators Nederland' src={VanLogo} style={{ height: '120px', filter: 'grayscale(100%)' }} />
        </div>
      </div>
      </div>
    )
  }
}

export default Affiliate