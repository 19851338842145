import React from 'react'
import Card from '../ui-objects/Card'
import Spacer from '../ui-objects/Spacer'


class Reviews extends React.Component {
  render() {
    return (
      <div>
        <h3 style={{ textAlign: 'center', color: 'white' }}>Dit zeggen klanten</h3>
        <Spacer size="xxl" custom="50px"/>
        <div className='reviewstyles'>
          <Card
            shade={false}
            border={false}
            showQuote={true}
            width={"33%"}
            cardHeader={"Ingeborg"}
            cardContent={"Betrokken, daadkrachtig, oplossingsgericht en een goed inlevingsvermogen zijn zomaar een aantal woorden die Karian typeren. Met haar fijne persoonlijkheid creëert ze eerlijkheid en openheid in haar gesprekken. Karian bedankt en veel succes met alles in de toekomst!"}
            showButton={false}
            buttonType={"text"}
            buttonLabel={"Lees meer..."}
            url={"post.fields.slug"}
            key={1}
          />
          <Card
            shade={false}
            width={"33%"}
            showQuote={true}
            cardHeader={"Lotte"}
            cardContent={"Karian is een fijne en professionele mediator, die partijen op prettige wijze begeleidt in hun zoektocht naar een goede oplossing. Zowel betrokken en empatisch als zakelijk en oplossingsgericht. Een aanbeveling waard!"}
            showButton={false}
            buttonType={"text"}
            buttonLabel={"Lees meer..."}
            url={"post.fields.slug"}
            key={2}
          />
          <Card
            shade={false}
            width={"33%"}
            showQuote={true}
            cardHeader={"Nicole"}
            cardContent={"Ik heb Karian ervaren als iemand bij wie je je snel op je gemak voelt. Ze creëert een veilige sfeer waarin je je vrij voelt om alles te zeggen. Ik raad haar van harte aan."}
            showButton={false}
            buttonType={"text"}
            buttonLabel={"Lees meer..."}
            url={"post.fields.slug"}
            key={3}
          />
        </div>
      </div>
    )
  }
}

export default Reviews
