import React, { Component } from "react";
import { Icon } from 'react-icons-kit'
import { androidDone } from 'react-icons-kit/ionicons/androidDone'

class UspBar extends Component {
  render() {
    return (
      <div className="container usp-bar">
        <div className=""><Icon size={26} icon={androidDone} style={{ paddingRight: '8px', position: 'relative', top: '-2px' }} />Persoonlijk & toegankelijk</div>
        <div className=""><Icon size={26} icon={androidDone} style={{ paddingRight: '8px', position: 'relative', top: '-2px' }} />Onpartijdige en transparante werkwijze</div>
        <div className=""><Icon size={26} icon={androidDone} style={{ paddingRight: '8px', position: 'relative', top: '-2px' }} />MfN Register Mediator</div>
      </div>
    )
  }
}

export default UspBar