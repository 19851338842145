import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import Card from "../ui-objects/Card";
import "../scss/_index.scss";

class BlogRow extends Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <section className="blogrow">
        {posts &&
          posts.map(({ node: post }) => (
            <Card
              shade={false}
              featuredPost={post.frontmatter.featuredpost}
              width={"33%"}
              cardHeader={post.frontmatter.title}
              cardContent={post.excerpt}
              showButton={true}
              buttonType={"text"}
              buttonLabel={"Lees meer..."}
              url={post.fields.slug}
              key={post.id}
            />
          ))}
      </section>
    );
  }
}

BlogRow.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

const queryRow = () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          limit: 3
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                featuredpost
              }
              id
              excerpt(pruneLength: 125)
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRow data={data} count={count} />}
  />
);

export default queryRow
